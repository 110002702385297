import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  ACTIVE_DUTY_MAPS,
  MAPS as DATABASE_MAPS,
  WEAPONS as DATABASE_WEAPONS,
} from "@/game-cs2-marketing/constants.mjs";
import { GAME_MODE_TYPES, MAPS, WEAPONS } from "@/game-csgo/constants.mjs";
import { getSearchParams } from "@/game-csgo/csgo-fetch-stats-data.mjs";
import Static from "@/game-csgo/static.mjs";
import { getGameModeById, getWeaponIcon } from "@/game-csgo/utils.mjs";
// import getTierIcon from "@/shared/get-tier-icon.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const useStats = ({ tab, searchParams }) => {
  const { t } = useTranslation();
  const {
    csgo: { stats },
  } = useSnapshot(readState);
  const searchParam = getSearchParams(searchParams);
  const gameMode = getGameModeById(searchParams.get("mode"));
  const key = btoa(new URLSearchParams(searchParam).toString());
  const cols = {
    maps: [
      { display: t("common:map", "Map"), align: "left", primary: true },
      {
        display: t("common:playRate", "Play Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:tWinPerc", "T-Win %"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:ctWinPerc", "CT-Win %"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:matches", "Matches"),
        align: "right",
        isStat: true,
      },
    ],
    weapons: [
      {
        display: t("common:weapon", "Weapon"),
        align: "left",
        primary: true,
      },
      // {
      //   display: t("common:tier", "Tier"),
      //   align: "center",
      //   primary: true,
      // },
      {
        display: t("common:kpr", "KPR"),
        tooltip: t("common:killsPerRound", "Kills / Round"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:stats.hsPercent", "HS %"),
        tooltip: t("common:headshotPerc", "Headshot %"),
        align: "right",
        isStat: true,
      },
      // {
      //   display: t("common:acc", "ACC"),
      //   tooltip: t("common:accuracy", "Accuracy"),
      //   align: "right",
      //   isStat: true,
      // },
      // {
      //   display: t("common:adr", "ADR"),
      //   tooltip: t("common:averageDamagePerRound", "Average Damage / Round"),
      //   align: "right",
      //   isStat: true,
      // },
      {
        display: t("common:chestPerc", "Chest %"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:legPerc", "Leg %"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:stats.totalKills", "Total Kills"),
        align: "right",
        isStat: true,
      },
    ],
  };

  const tableData = useMemo(() => {
    if (!stats[tab]?.[key] || stats[tab]?.[key] instanceof Error) return null;

    if (tab === "maps") {
      return Object.entries(stats.maps[key] || {})
        .filter(([mapId]) => ACTIVE_DUTY_MAPS.includes(mapId))
        .map(([mapId, stats]) => {
          const isDeathmatch = gameMode.type === GAME_MODE_TYPES.DEATHMATCH;
          return [
            {
              display: (
                <div className="map-meta">
                  <img
                    src={Static.getMapImage(mapId)}
                    alt={MAPS[mapId]?.name}
                  />
                  <span>{t(MAPS[mapId]?.i18nKey, MAPS[mapId]?.name)}</span>
                </div>
              ),
              value: MAPS[mapId]?.name,
              link: Object.keys(DATABASE_MAPS).includes(mapId)
                ? `/cs2/database/maps/${mapId}/overview`
                : null,
            },
            {
              display: getLocaleString(stats.pick_rate, percentOptions),
              value: stats.pick_rate,
            },
            {
              display: !isDeathmatch
                ? getLocaleString(stats.t_win_rate, percentOptions)
                : "-",
              value: !isDeathmatch ? stats.t_win_rate : null,
            },
            {
              display: !isDeathmatch
                ? getLocaleString(stats.ct_win_rate, percentOptions)
                : "-",
              value: !isDeathmatch ? stats.ct_win_rate : null,
            },
            {
              display: getLocaleString(stats.num_matches),
              value: stats.num_matches,
            },
          ];
        });
    }

    if (tab === "weapons") {
      return Object.entries(stats.weapons[key] || {}).map(
        ([weaponId, stats]) => {
          const weapon = WEAPONS[weaponId];
          return [
            {
              display: (
                <div className="weapon-meta">
                  <img
                    className="weapon-image"
                    src={getWeaponIcon(weapon?.icon)}
                    width={64}
                    height={32}
                    alt={weapon.name}
                  />
                  <div>
                    <span className="weapon-name type-body2-form--bold">
                      {weapon.name}
                    </span>
                  </div>
                </div>
              ),
              value: weapon.name,
              link: Object.keys(DATABASE_WEAPONS).includes(weaponId)
                ? `/cs2/database/weapons/${DATABASE_WEAPONS[weaponId].key}/overview`
                : null,
            },
            // {
            //   display: <TierIcon />,
            //   value: weaponTier,
            // },
            {
              display: getLocaleString(stats.kills_per_round, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              value: stats.kills_per_round,
            },
            {
              display: getLocaleString(stats["head_%"], percentOptions),
              value: stats["head_%"],
            },
            // {
            //   display: getLocaleString(acc, percentOptions),
            //   value: acc,
            // },
            // {
            //   display: getLocaleString(adr),
            //   value: adr,
            // },
            {
              display: getLocaleString(stats["body_%"], percentOptions),
              value: stats["body_%"],
            },
            {
              display: getLocaleString(stats["leg_%"], percentOptions),
              value: stats["leg_%"],
            },
            {
              display: getLocaleString(
                stats.num_rounds * stats.kills_per_round,
              ),
              value: stats.num_rounds * stats.kills_per_round,
            },
          ];
        },
      );
    }
  }, [stats, tab, key, t, gameMode]);

  return {
    cols: cols[tab],
    rows: tableData,
  };
};

export default useStats;
